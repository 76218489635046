

$(function ($) {
  $(".faq-item_a").hide();
  $(".faq-item_q").click(function () {
    $(this).toggleClass("active").next().slideToggle("show");
  });

});


