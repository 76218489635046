var topBtn = $('.c-scrollPageTop');
topBtn.hide();
//スクロールが100に達したらボタン表示
$(window).scroll(function () {
  if ($(this).scrollTop() > 500) {
    topBtn.fadeIn();
  } else {
    topBtn.fadeOut();
  }
});
